.model {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 30%;
    transform: translate(-50%, -50%);
    /*box-shadow: 24px;*/
    border-radius: 10px;
    padding: 10px;
}

.btnIcon {
    position: absolute !important;
    top: 10px !important;
    right: 20px !important;

}

.editButton {
    background-color: purple !important;
    color: white !important;
    margin-right: 4px !important;
    margin-bottom: 4px !important;
}

.deleteButton {
    background-color: red !important;
    color: white !important;
}